import { accessToken, management_token, spaceId, environment } from "../config.js"

// Function to throttle requests
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Retry wrapper for fetch requests with timeout
const fetchWithRetry = async (url, options = {}, retries = 3, delayMs = 1000, timeoutMs = 5000) => {
    for (let i = 0; i < retries; i++) {
        try {
            console.log(`Attempt ${i + 1}: Fetching ${url}`);

            const fetchPromise = fetch(url, options);
            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeoutMs)
            );

            const response = await Promise.race([fetchPromise, timeoutPromise]);

            if (response.ok) {
                return response;
            } else {
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error(`Attempt ${i + 1} failed: ${error.message}`);
            if (i < retries - 1) {
                await delay(delayMs); // Wait before retrying
            }
        }
    }
    throw new Error('Max retries reached');
};

// Function to fetch unpublished entries with rate limiting
const fetchUnpublishedEntries = async () => {
    const limit = 1000;
    let allUnpublishedItems = [];
    let skip = 0;
    let total;

    const url = `https://api.contentful.com/spaces/${spaceId}/environments/${environment}/entries`;

    do {
        const queryString = new URLSearchParams({
            limit: limit.toString(),
            skip: skip.toString(),
            access_token: management_token,
        });
        try {
            const response = await fetchWithRetry(`${url}?${queryString.toString()}`, {
                headers: {
                    Authorization: `Bearer ${management_token}`,
                    'Content-Type': 'application/json',
                },
            }, 3, 1000, 10000); // Adding timeout for each fetch

            const data = await response.json();
            total = data.total;
            allUnpublishedItems = allUnpublishedItems.concat(data.items);
            skip += limit;
            console.log(`Fetched ${data.items.length} items, Total: ${total}`);
        } catch (error) {
            console.error(`Error fetching unpublished entries: ${error.message}`);
            break; // Break the loop on error to prevent hanging
        }

        await delay(1000); // Throttle requests to avoid hitting the rate limit
    } while (skip < total);

    return allUnpublishedItems;
};


export async function getContents() {
    try {
        const limit = 1000;
        let allItems = [];
        let allAssets = [];

        let content = sessionStorage.getItem('content') && JSON.parse(sessionStorage.getItem('content'));
        let total = content?.total;
        const url = `https://cdn.contentful.com/spaces/${spaceId}/environments/${environment}/entries`;

        if (!total) {
            const queryString = new URLSearchParams({
                limit: limit.toString(),
                access_token: accessToken,
            });

            const response = await fetchWithRetry(`${url}?${queryString.toString()}`, {}, 3, 1000); // Using fetchWithRetry
            const responseData = await response.json();
            total = responseData.total;
            sessionStorage.setItem('content', JSON.stringify(responseData));
        } else {
            total = parseInt(total, 10);
        }

        const totalPages = Math.ceil(total / limit);

        const fetchPromises = [];
        for (let i = 0; i < totalPages; i++) {
            const skip = i * limit;
            const paginatedQueryString = new URLSearchParams({
                limit: limit.toString(),
                skip: skip.toString(),
                access_token: accessToken,
            });

            fetchPromises.push(fetchWithRetry(`${url}?${paginatedQueryString.toString()}`, {}, 3, 1000)); // Use fetchWithRetry here as well
        }

        const responses = await Promise.all(fetchPromises);
        for (const paginatedResponse of responses) {
            if (!paginatedResponse.ok) {
                throw new Error('Failed to fetch content');
            }
            const paginatedData = await paginatedResponse.json();
            allItems = allItems.concat(paginatedData.items);
            const paginatedAssets = paginatedData.includes?.Asset || [];
            allAssets = allAssets.concat(paginatedAssets);
        }

        // Process the assets
        const assetMap = new Map();
        allAssets.forEach(asset => assetMap.set(asset.sys.id, asset));

        allItems.forEach(item => {
            const assetId = item?.fields?.postCoverImage?.sys?.id;
            if (assetId && assetMap.has(assetId)) {
                item.asset = assetMap.get(assetId);
            }
        });

        allItems.sort((a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt));

        // Fetch unpublished entries and compare with published entries
        const unpublishedEntries = await fetchUnpublishedEntries();
        unpublishedEntries.forEach(unpublishedEntry => {
            const index = allItems.findIndex(item => item.sys.id === unpublishedEntry.sys.id);
            if (index !== -1 && unpublishedEntry.fields.likes) {
                allItems[index].fields.likes = unpublishedEntry.fields.likes;
            }
        });

        return allItems;
    } catch (error) {
        console.error('Error fetching content:', error);
        throw error;
    }
}
