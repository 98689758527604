const msalConfig = {
    auth: {
        clientId: 'f9205187-fe77-43b6-aefd-b1dbd5897e54',
        redirectUri: 'https://spaces.kpsworld.com',

        // clientId: 'a11cf81a-9433-49bd-976b-627255a61a0a',
        // redirectUri: 'http://localhost:3000',
        authority: 'https://login.microsoftonline.com/kpsworld.com',
    },
};

const spaceId = 'jfdnrd5l5y2z';
const environment = 'master';
const accessToken = 'cjMgLw0lsMRyAMVDq-XpnvcV5wJY1cZSJRMwljjxISs';
const management_token ='CFPAT-o7IUTULKIrLRwZiqgAagdVz9TMyXCOFe33xdAn67x4Y';

export { msalConfig, spaceId, environment, accessToken, management_token }
