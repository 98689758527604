import React, { useEffect, useState } from "react";
import moment from 'moment';
import './card.scss';
import LikeButton from '../Like/LikeButton';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Card({ data, index }) {
    const _baseClass = "card";
    const languageCode = "en-US";
    const { asset, fields, sys } = data;
    const imageUrl = asset?.fields?.file?.url || '';
    const formattedDate = moment(sys?.createdAt);
    const postId = sys.id;

    const { postDepartmentList, postTitle } = fields


    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        setImageLoaded(false);

        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => setImageLoaded(true);
        }
    }, [imageUrl]);


    let handleClick = () => {
        navigate("/article/" + sys?.id)
    }

    return (
        <div onClick={handleClick} className={`${_baseClass} p-[12px] sm:p-[20px] w-full h-full flex flex-col rounded-[16px] bg-[#FFF]`}>
            {/* Image Container */}
            <div className={`${_baseClass}-image-container flex items-center ${index === 0 ? " h-[250px]" : " h-[120px] sm:h-[250px]"}`}>
                {/* Skeleton Image Placeholder */}
                {!imageLoaded && (
                    <div className={`${_baseClass}-image-skeleton min-h-full w-full bg-[#f0f0f0] animate-pulse rounded-[16px]`} />
                )}
                {/* Actual Image */}
                {imageLoaded && (
                    <img className={`${_baseClass}-image min-h-full w-full object-cover`} src={imageUrl} alt="" />
                )}
            </div>

            {/* Content Container */}
            <div className={`${_baseClass}-content flex flex-col mt-[12px] sm:mt-[20px] text-left`}>
                {/* Title */}
                {fields?.postDepartmentList ? (
                    <div className={`${_baseClass}-title font-semibold text-[12px] text-[#1C355E] sm:text-[14px] uppercase`}>
                        {fields?.postDepartmentList}
                    </div>
                ) : (
                    <div className={`${_baseClass}-title-skeleton w-full h-[12px] bg-[#f0f0f0] animate-pulse rounded-[4px]`} />
                )}

                {/* Post Title */}
                {fields?.postTitle ? (
                    <div className={`${_baseClass}-info mt-[5px] w-full text-[18px] sm:mt-[20px] sm:text-[24px] line-clamp-2`}>
                        {fields?.postTitle}
                    </div>
                ) : (
                    <div className={`${_baseClass}-info-skeleton w-full h-[18px] sm:h-[24px] bg-[#f0f0f0] animate-pulse rounded-[4px]`} />
                )}

                {/* Date */}
                {sys?.createdAt ? (
                    <div className={`${_baseClass}-time mt-[5px] sm:mt-[30px] text-[12px]`}>
                        {formattedDate.format('D MMM, YYYY')}
                    </div>
                ) : (
                    <div className={`${_baseClass}-time-skeleton w-1/3 h-[12px] bg-[#f0f0f0] animate-pulse rounded-[4px]`} />
                )}

                <LikeButton data={data} />

            </div>
        </div>
    );
}

export default Card;
